<template>
  <div>
    <div class="crumbs">通讯日志</div>
    <div class="center">
      <div class="table">
        <div class="center_top">
          <span>
            <el-form :inline="true" class="demo-form-inline">
              <el-form-item label="device_id查询">
                <el-input
                  placeholder="请输device_id"
                  v-model="serch_device_id"
                ></el-input>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" size="medium" @click="getList"
                  >查询</el-button
                >
              </el-form-item>
            </el-form>
          </span>
        </div>
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#F5F7FA', 'text-align': 'center' }"
        >
          <el-table-column prop="device_id" label="device_id" align="center">
          </el-table-column>
          <el-table-column prop="msg" label="msg" align="center">
          </el-table-column>
          <el-table-column prop="session" label="session" align="center">
          </el-table-column>
          <el-table-column prop="date" label="date" align="center">
          </el-table-column>
          <el-table-column prop="c_time" label="c_time" align="center">
          </el-table-column>
          <el-table-column prop="cmd" label="cmd" align="center">
          </el-table-column>
          <el-table-column prop="data" label="data" align="center">
          </el-table-column>

          <el-table-column prop="point_name" label="point_name" align="center">
          </el-table-column>
          <el-table-column prop="is_async" label="is_async" align="center">
          </el-table-column>
          <el-table-column prop="length" label="length" align="center">
          </el-table-column>
          <!-- <el-table-column label="操作" :align="'center'">
            <template slot-scope="scope">
              <el-button type="text" @click="editStreetEvent(scope.row)"
                >编辑街道</el-button
              >
              <el-button
                type="text"
                style="color: red"
                @click="delStreetEvent(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>
      </div>

      <div>
        <span style="float: right; margin-top: 20px; margin-right: 20px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="1"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="counts"
            background
          >
          </el-pagination>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { deviceLog } from "../request/api";
export default {
  name: "log",
  data() {
    return {
      counts: 0, //总数
      page: 1, //当前页
      limit: 10, //一页展示几条
      tableData: [],
      serch_device_id: "",
    };
  },
  methods: {
    async getList() {
      const res = await deviceLog({
        device_id: this.serch_device_id,
        page: this.page,
        limit: this.limit,
      });

      this.tableData = res.data.list;
      this.counts = res.data.count;
    },
    handleSizeChange(val) {
      this.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.crumbs {
  padding: 5px 20px;
  background-color: rgb(240, 240, 240);
}

.center {
  width: 100vw;
  height: calc(100% - 31px);
  margin: auto;
  overflow: auto;
  box-sizing: border-box;
}

.abc {
  display: flex;
  flex-wrap: wrap;
  margin-left: 25px;
}

::v-deep .el-radio {
  margin: 5px 0;
  margin-right: 20px;
}

.table {
  height: calc(100vh - 100px);
  overflow: auto;
  padding: 0 10px;
}

.center_top {
  padding-top: 10px;
  padding-bottom: 13px;
  height: 36px;
  display: flex;
  justify-content: center;

  ::v-deep .el-input__inner {
    width: 190px;
    height: 32px;
  }
}
</style>
